import Enum from 'enum';

// Fantastic Cards
const CardType = new Enum(
  ['INFO', 'PROMO', 'CREATEPREVIEW', 'UPDATEPREVIEW', 'LOADER']
);
const CardType_INFO = CardType.INFO.value;
const CardType_PROMO = CardType.PROMO.value;
const CardType_CREATEPREVIEW = CardType.CREATEPREVIEW.value;
const CardType_UPDATEPREVIEW = CardType.UPDATEPREVIEW.value;
const CardType_LOADER = CardType.LOADER.value;

// Fantastic Card Input
const InputType = new Enum([
  'FIELD',
  'PASSWORD',
  'BUTTON',
  'PAYPAL_CHECKOUT_BUTTON',
  'TAG',
  'TAGS',
  'LINKS',
  'TEXT',
  'TAGFIELD',
  'CARDPREVIEW',
  'DROPDOWN',
  'DATEPICKER',
  'LABEL',
  'SLIDER',
  'LIST',
  'MESSAGE',
  'STRIPE_CHECKOUT_FORM',
  'TOGGLE',
  'OPTIONS',
  'SPACER',
]);

const InputType_TEXT = InputType.TEXT.value;
const InputType_FIELD = InputType.FIELD.value;
const InputType_PASSWORD = InputType.PASSWORD.value;
const InputType_BUTTON = InputType.BUTTON.value;
const InputType_PAYPAL_CHECKOUT_BUTTON = InputType.PAYPAL_CHECKOUT_BUTTON.value;
const InputType_TAG = InputType.TAG.value;
const InputType_TAGS = InputType.TAGS.value;
const InputType_LINKS = InputType.LINKS.value;
const InputType_TAGFIELD = InputType.TAGFIELD.value;
const InputType_CARDPREVIEW = InputType.CARDPREVIEW.value;
const InputType_DROPDOWN = InputType.DROPDOWN.value;
const InputType_DATEPICKER = InputType.DATEPICKER.value;
const InputType_TOGGLE = InputType.TOGGLE.value;
const InputType_LABEL = InputType.LABEL.value;
const InputType_SLIDER = InputType.SLIDER.value;
const InputType_LIST = InputType.LIST.value;
const InputType_MESSAGE = InputType.MESSAGE.value;
const InputType_OPTIONS = InputType.OPTIONS.value;
const InputType_SPACER = InputType.SPACER.value;
const InputType_STRIPE_CHECKOUT_FORM = InputType.STRIPE_CHECKOUT_FORM.value;

// Fantastic Card Tag Type
const TagType = new Enum(['USER','DEFAULT', 'ACTION']);
const TagType_USER = TagType.USER.value;
const TagType_DEFAULT = TagType.DEFAULT.value;
const TagType_ACTION = TagType.ACTION.value;

// Fantastic Card Action Tag Type
const ActionTagType = new Enum(['FAN','SAVE']);
const ActionTagType_FAN = ActionTagType.FAN.value;
const ActionTagType_SAVE = ActionTagType.SAVE.value;

// Fantastic User Role Id
// 1 - DEFAULT
// 2 - INSIGHTS
// 4 - ADMIN
// 8 - SUPERADMIN
// 16 - TEST
// 32 - TEMP
// 64 - UPWORK
// 128 - CURATOR (PAID)

const RoleId = new Enum([
  'DEFAULT', 'INSIGHTS', 'ADMIN', 'SUPERADMIN', 'TEST', 'TEMP', 'UPWORK', 'CURATOR'
]);
const RoleId_DEFAULT = RoleId.DEFAULT.value;
const RoleId_INSIGHTS = RoleId.INSIGHTS.value;
const RoleId_ADMIN = RoleId.ADMIN.value;
const RoleId_SUPERADMIN = RoleId.SUPERADMIN.value;
const RoleId_TEST = RoleId.TEST.value;
const RoleId_TEMP = RoleId.TEST.value;

// Fantastic Insights User Role Id
const Insights_RoleId = new Enum(['DEFAULT', 'ADMIN', 'DEACTIVATED']);
const Insights_RoleId_DEACTIVATED = Insights_RoleId.DEACTIVATED.value;
const Insights_RoleId_DEFAULT = Insights_RoleId.DEFAULT.value;
const Insights_RoleId_ADMIN = Insights_RoleId.ADMIN.value;

// Fantastic Insights Pricing Id
const Insights_Audience_PricingId = new Enum(['FREE', 'INSIGHTS_PASS', 'UNLIMITED', 'SAMPLE']);
const Insights_Audience_PricingId_FREE = Insights_Audience_PricingId.FREE.value;
const Insights_Audience_PricingId_INSIGHTS_PASS = Insights_Audience_PricingId.INSIGHTS_PASS.value;
const Insights_Audience_PricingId_UNLIMITED = Insights_Audience_PricingId.UNLIMITED.value;
const Insights_Audience_PricingId_SAMPLE = Insights_Audience_PricingId.SAMPLE.value;

// Time Rate
const TimeRate = new Enum(['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY', 'ANNUAL', 'ALL_TIME']);
const TimeRate_HOURLY = TimeRate.HOURLY.value;
const TimeRate_DAILY = TimeRate.DAILY.value;
const TimeRate_WEEKLY = TimeRate.WEEKLY.value;
const TimeRate_MONTHLY = TimeRate.MONTHLY.value;
const TimeRate_ANNUAL = TimeRate.ANNUAL.value;
const TimeRate_ALL_TIME = TimeRate.ALL_TIME.value;

// Age Range
const AgeRange = new Enum(['RANGE_13_17', 'RANGE_18_24', 'RANGE_25_34', 'RANGE_35_44', 'RANGE_45_54', 'RANGE_55_64', 'RANGE_65_PLUS']);
const AgeRange_13_17 = AgeRange.RANGE_13_17.value;
const AgeRange_18_24 = AgeRange.RANGE_18_24.value;
const AgeRange_25_34 = AgeRange.RANGE_25_34.value;
const AgeRange_35_44 = AgeRange.RANGE_35_44.value;
const AgeRange_45_54 = AgeRange.RANGE_45_54.value;
const AgeRange_55_64 = AgeRange.RANGE_55_64.value;
const AgeRange_65_PLUS = AgeRange.RANGE_65_PLUS.value;

// Gender
const Gender = new Enum(['MALE', 'FEMALE', 'NON_BINARY']);
const Gender_MALE = Gender.MALE.value;
const Gender_FEMALE = Gender.FEMALE.value;
const Gender_NON_BINARY = Gender.NON_BINARY.value;

// Feed type
const FeedType = new Enum(['EXPLORE', 'FAN', 'SAVED', 'FOLLOWING', 'SUBMISSIONS', 'PEOPLE', 'SIMILAR']);
const FeedType_EXPLORE = FeedType.EXPLORE.value;
const FeedType_FAN = FeedType.FAN.value;
const FeedType_SAVED = FeedType.SAVED.value;
const FeedType_FOLLOWING = FeedType.FOLLOWING.value;
const FeedType_SUBMISSIONS = FeedType.SUBMISSIONS.value;
const FeedType_SIMILAR = FeedType.SIMILAR.value;
const FeedType_PEOPLE = FeedType.PEOPLE.value;

export {
  CardType_INFO,
  CardType_PROMO,
  CardType_CREATEPREVIEW,
  CardType_UPDATEPREVIEW,
  CardType_LOADER,

  InputType_FIELD,
  InputType_PASSWORD,
  InputType_BUTTON,
  InputType_PAYPAL_CHECKOUT_BUTTON,
  InputType_TAG,
  InputType_TAGS,
  InputType_LINKS,
  InputType_TEXT,
  InputType_TAGFIELD,
  InputType_CARDPREVIEW,
  InputType_DROPDOWN,
  InputType_DATEPICKER,
  InputType_TOGGLE,
  InputType_LABEL,
  InputType_SLIDER,
  InputType_LIST,
  InputType_MESSAGE,
  InputType_OPTIONS,
  InputType_SPACER,
  InputType_STRIPE_CHECKOUT_FORM,

  TagType_USER,
  TagType_DEFAULT,
  TagType_ACTION,

  ActionTagType_FAN,
  ActionTagType_SAVE,

  RoleId_DEFAULT,
  RoleId_INSIGHTS,
  RoleId_ADMIN,
  RoleId_SUPERADMIN,
  RoleId_TEST,
  RoleId_TEMP,

  Insights_RoleId_DEACTIVATED,
  Insights_RoleId_DEFAULT,
  Insights_RoleId_ADMIN,

  Insights_Audience_PricingId_FREE,
  Insights_Audience_PricingId_INSIGHTS_PASS,
  Insights_Audience_PricingId_UNLIMITED,
  Insights_Audience_PricingId_SAMPLE,

  TimeRate_DAILY,
  TimeRate_WEEKLY,
  TimeRate_MONTHLY,
  TimeRate_ANNUAL,
  TimeRate_ALL_TIME,

  AgeRange_13_17,
  AgeRange_18_24,
  AgeRange_25_34,
  AgeRange_35_44,
  AgeRange_45_54,
  AgeRange_55_64,
  AgeRange_65_PLUS,

  Gender_MALE,
  Gender_FEMALE,
  Gender_NON_BINARY,

  FeedType_EXPLORE,
  FeedType_FAN,
  FeedType_SAVED,
  FeedType_FOLLOWING,
  FeedType_SUBMISSIONS,
  FeedType_SIMILAR,
  FeedType_PEOPLE
};
