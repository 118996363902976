import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Switch,
  useParams
} from 'react-router-dom';

import PWAPrompt from 'react-ios-pwa-prompt';
import mixpanel from 'mixpanel-browser';


mixpanel.init('317a4c5f6fc811e3bb548ba0a0639443', {debug: true, track_pageview: true, persistence: 'localStorage'});


// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
// <Elements stripe={stripePromise}> </Elements>
import {
  FeedType_EXPLORE,
  FeedType_FOLLOWING,
  FeedType_FAN,
  FeedType_SAVED,
  FeedType_SUBMISSIONS,
  FeedType_PEOPLE
} from '../shared/constants';

const FantasticPage = lazy(() => import('../shared/components/FantasticPage'));
const FantasticInsightsPage = lazy(() => import('../shared/components/FantasticInsightsPage'));

if (typeof window !== "undefined") {
  window.onload = function() {
    ReactDOM.render(
      <span>
        <Suspense fallback={<div/>}>
          <BrowserRouter>
            <Switch>
              <Route path={[
                "/signup",
                "/brands",
                "/legal/terms",
                "/legal/privacy",
                "/legal/copyright",
                "/resetPassword",
              ]}>
                <FantasticPage mixpanel={mixpanel} />
              </Route>

              <Route path="/insights/documentation">
                <FantasticInsightsPage showDocumentation={true} mixpanel={mixpanel} />
              </Route>

              <Route path={[
                "/insights/workspace/invite/:workspace_invite_id",
                "/insights/workspace/:workspace_id",
                "/insights"
              ]}>
                <FantasticInsightsPage mixpanel={mixpanel} />
              </Route>

              <Route path="/requests/:audience_panel_uuid">
                <FantasticPage mixpanel={mixpanel} />
              </Route>

              <Route path="/:username/favorites">
                <FantasticPage feedType={FeedType_FAN} mixpanel={mixpanel} />
              </Route>
              <Route path="/:username/submissions">
                <FantasticPage feedType={FeedType_SUBMISSIONS} mixpanel={mixpanel} />
              </Route>
              <Route path="/:username/following">
                <FantasticPage feedType={FeedType_FOLLOWING} mixpanel={mixpanel} />
              </Route>
              <Route path="/:username/discover">
                <FantasticPage feedType={FeedType_EXPLORE} mixpanel={mixpanel} />
              </Route>
              <Route path="/:username">
                <FantasticPage mixpanel={mixpanel} />
              </Route>

              <Route exact path="/">
                <FantasticPage mixpanel={mixpanel} />
              </Route>
            </Switch>
          </BrowserRouter>
        </Suspense>
        <PWAPrompt
          promptOnVisit={0}
          timesToShow={0}
          copyTitle={`Add to home screen.`}
          copyBody={`This website can be used as an app. Add it to your home screen through Safari for a better mobile experience.`}
          copyClosePrompt={`Close`}
          copyShareButtonLabel= {`1) Press the 'Share' button.`}
          copyAddHomeButtonLabel={`2) Scroll until 'Add to Home Screen' is visible. Press to install app on your homescreen.`}
          debug={false}
          permanentlyHideOnDismiss={false}/>
      </span>,
      document.getElementById('root')
    );
  };
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}
